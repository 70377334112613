<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="编辑入库" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div >
                <p class="font-bold">试剂信息</p>
                <table>
                    <tr>
                        <td class="tag">试剂组别</td>
                        <td>{{edit_ruku.group_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂名称</td>
                        <td>{{edit_ruku.reagent_info_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">拼音代码</td>
                        <td>{{edit_ruku.pinyin}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂规格</td>
                        <td>{{edit_ruku.spec}}</td>
                    </tr>
                    <tr>
                        <td class="tag">生产厂家</td>
                        <td>{{edit_ruku.full_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">条形码</td>
                        <td>{{edit_ruku.bar_code}}</td>
                    </tr>
                </table>
                <a-form id="components-form-demo-validate-other" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10 }"  v-bind="formItemLayout" @submit="handleSubmit">
                    <a-form-item label="批准文号">
                        <a-input  v-model="edit_ruku.approval_number" />
                    </a-form-item>

                    <a-form-item label="生产批号">
                        <a-input  v-model="edit_ruku.batch" />
                    </a-form-item>

                    <a-form-item label="试剂有效期">
                        <a-date-picker v-model="edit_ruku.valid_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                    </a-form-item>

                    <a-form-item label="库存上限">
                        <a-input  v-model="edit_ruku.Inventory_online" />
                    </a-form-item>

                    <a-form-item label="库存下限">
                        <a-input  v-model="edit_ruku.Inventory_offline" />
                    </a-form-item>

                    <a-form-item label="入库时间">
                        <a-date-picker v-model="edit_ruku.do_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
                    </a-form-item>

                    <a-form-item label="入库数量">
                        <a-input v-model="edit_ruku.stock_num" />
                    </a-form-item>

                    <a-form-item label="存放地点">
                        <a-select  v-model="edit_ruku.storage_location"   @change="handleChange">
                            <a-select-option  :value="item.id" v-for="(item,key) in cunfang_list">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="试剂价格">
                        <a-input  v-model="edit_ruku.price" />
                    </a-form-item>

                    <a-form-item label="总金额">
                       <div>￥{{ info_total }}</div>
                    </a-form-item>

                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                       <a-button type="primary" class="mr20" @click="doClick()">确认修改</a-button>
                    </a-form-item>
                </a-form>

            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close','edit_ruku'],

        data: () => ({
            cunfang_list:[],
            options:[],
            value: [],
            startValue: null,
            endValue: null,
            endOpen: false,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },
        }),

        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'validate_other'});
        },
        //计算试剂总额
        computed:{
            info_total(){
                return (this.edit_ruku.stock_num*this.edit_ruku.price).toFixed(2)
            },
        },

        mounted() {
            console.log('---------------------------------',JSON.stringify(this.edit_ruku))
            if (this.edit_ruku.do_time == '') {
                this.edit_ruku.do_time = this.formatDateTime(new Date() / 1)
            }
            this.Sampletypelist()//获取存放地点
        },
        watch: {
            startValue(val) {
                console.log('startValue', val);
            },

        },
        methods: {

            //显示当前时间
            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            },

            //确认修改
            doClick() {
                let data = {
                    reagent_stock_id: this.edit_ruku.id,
                    stock_num: this.edit_ruku.stock_num,
                    price: this.edit_ruku.price,
                    storage_location:this.edit_ruku.storage_location,
                    batch: this.edit_ruku.batch,
                    valid_time: this.edit_ruku.valid_time,
                    Inventory_online: this.edit_ruku.Inventory_online,
                    approval_number: this.edit_ruku.approval_number,
                    Inventory_offline: this.edit_ruku.Inventory_offline,
                }
                console.log(this.edit_ruku.storage_location),
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Edit_reagentstock'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已修改')
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //获取存放地点列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.cunfang_list = response.data.list;
                            console.log('-----------------', this.cunfang_list)
                            //return
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //select
            handleChange(value) {
                console.log(`selected ${value}`);
            },


            //时间组件
            onChangetime(value, dateString, e) {
                this.info[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },

            // 时间
            disabledStartDate(startValue) {
                const endValue = this.endValue;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.startValue;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            onChange(value) {
                console.log('changed', value);
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .mr20{ margin-right: 10px}
    table{
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }
    table td, table th{
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }
    table th{padding: 12px 15px; background: #f5f5f5}
    table td.tag{background: #f5f5f5; width: 20%; text-align: right; font-weight: bold}
</style>
