<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="试剂报损" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <p class="font-bold">试剂信息</p>
                <table>
                    <tr>
                        <td class="tag">试剂组别</td>
                        <td>{{shiji_info.group_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂名称</td>
                        <td>{{shiji_info.reagent_info_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">拼音代码</td>
                        <td>{{shiji_info.pinyin}}</td>
                    </tr>
                    <tr>
                        <td class="tag">生产厂家</td>
                        <td>{{shiji_info.full_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">生产批号</td>
                        <td>{{shiji_info.approval_number}}</td>
                    </tr>
                    <tr>
                        <td class="tag">有效日期</td>
                        <td>{{shiji_info.valid_time}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂规格</td>
                        <td>{{shiji_info.spec}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂状态</td>
                        <td>{{shiji_info.reagent_info_status}}</td>
                    </tr>
                    <tr>
                        <td class="tag">库存上限</td>
                        <td>{{shiji_info.Inventory_online}}</td>
                    </tr>
                    <tr>
                        <td class="tag">库存下限</td>
                        <td>{{shiji_info.Inventory_offline}}</td>
                    </tr>
                    <tr>
                        <td class="tag">存放地点</td>
                        <td>{{shiji_info.storage_location}}</td>
                    </tr>
                </table>
                <a-form id="components-form-demo-validate-other" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10 }" v-bind="formItemLayout" @submit="handleSubmit">
                    <a-form-item label="报损时间">
                        <a-date-picker v-model="baosun_info.do_time" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
                    </a-form-item>
                    <a-form-item label="报损数量">
                        <a-input v-model="baosun_info.stock_num" />
                    </a-form-item>
                    <a-form-item label="报损原因">
                        <a-select placeholder="报损原因" v-model="baosun_info.reason_id">
                            <a-select-option :value="1">试剂破损</a-select-option>
                            <a-select-option :value="2">试剂过期</a-select-option>
                            <a-select-option :value="3">试剂变质</a-select-option>
                            <a-select-option :value="4">其他原因</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="试剂单价">
                        <a-input v-model="baosun_info.price"/>
                    </a-form-item>
                    <a-form-model-item label="总金额">
                        ￥{{ info_total }}
                    </a-form-model-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4}">
                            <a-button type="primary" @click="doClick()" >确认报损</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </a-drawer>

    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close','shiji_info'],

        data: () => ({

            baosun_info:{
                reagent_info_id: '',
                stock_num: '',
                type: 1,
                ordernum: '',
                // user_id:'',
                reagent_info_name: '',
                price: '',
                do_time: '',
                reason_id: '',
                storage_location: '',
                reagent_stock_id: '',
            },

            // value: 1,
            startValue: null,
            endValue: null,
            endOpen: false,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },
        }),
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'validate_other'});
        },

        computed:{
            info_total(){
                return (this.baosun_info.stock_num*this.baosun_info.price).toFixed(2)
            },
        },

        mounted() {
            this.baosun_info.reagent_info_id = this.shiji_info.reagent_info_id;
            this.baosun_info.reagent_info_name = this.shiji_info.reagent_info_name;
            this.baosun_info.reagent_stock_id = this.shiji_info.id;
            this.baosun_info.price = this.shiji_info.price;
            if (this.baosun_info.do_time == '') {
                this.baosun_info.do_time = this.formatDateTime(new Date() / 1)
            }

        },
        watch: {
            startValue(val) {
                console.log('startValue', val);
            },

        },
        methods: {
            //显示当前时间
            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            },
            //报损
            doClick() {
                let data = {
                    reagent_info_id: this.baosun_info.reagent_info_id,
                    stock_num: this.baosun_info.stock_num,
                    type: 2,
                    ordernum: this.baosun_info.ordernum,
                    reagent_info_name: this.baosun_info.reagent_info_name,
                    price: this.baosun_info.price,
                    do_time: this.baosun_info.do_time,
                     reason_id: this.baosun_info.reason_id,
                     storage_location: this.baosun_info.storage_location,
                    reagent_stock_id: this.baosun_info.reagent_stock_id,
                    //
                }
                console.log(this.baosun_info.id)
                console.log(this.baosun_info.reagent_info_id)
                console.log(JSON.stringify(data))

                this.$sa0.post({
                    url: this.$api('Create_reagentstock'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已报损')
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },

            // 时间
            disabledStartDate(startValue) {
                const endValue = this.endValue;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.startValue;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            onChange(value) {
                console.log('changed', value);
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .mr20{ margin-right: 10px}
    table{
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }
    table td, table th{
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }
    table th{padding: 12px 15px; background: #f5f5f5}
    table td.tag{background: #f5f5f5; width: 18%; text-align: right; font-weight: bold}
</style>
