<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <div v-if="!jg_open">
            <a-modal title="试剂警示信息" okText="确定" cancelText="取消" width="1200px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
                <div style="height:620px">
                    <a-row>
                        <div class="nav-tab">
                            <a-tabs default-active-key="tab_active" v-model="type" type="card" @change="tabChange">
                                <a-tab-pane key="1" tab="失效试剂"></a-tab-pane>
                                <a-tab-pane key="2" tab="近效试剂"></a-tab-pane>
                                <a-tab-pane key="3" tab="超下限试剂"></a-tab-pane>
                                <a-tab-pane key="4" tab="超上限试剂"></a-tab-pane>
                                <a-tab-pane key="5" tab="零库存试剂"></a-tab-pane>
                            </a-tabs>
                        </div>

                        <div style="height: 530px; overflow-y: auto">

                            <div v-if="Number(type) == 1">
                                <table class="layui-table"  lay-size="sm">
                                    <tr>
                                        <th>试剂名称</th>
                                        <th>试剂规格</th>
                                        <th>生产批号</th>
                                        <th>有效日期</th>
                                        <th>生产厂家</th>
                                        <th>数量</th>
                                        <th>价格</th>
                                        <th>单位</th>
                                        <th>金额</th>
                                        <th>组别</th>
                                    </tr>
                                    <tr @click="itemClick(item.reagent_info_name)" v-for="(item,key) in warn_list" :key="item.id">
                                        <td>{{item.reagent_info_name}}</td>
                                        <td>{{item.spec}}</td>
                                        <td>{{item.approval_number}}</td>
                                        <td>{{item.valid_time}}</td>
                                        <td>{{item.full_name}}</td>
                                        <td>{{item.stock_num}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.reagent_unit_name}}</td>
                                        <td>{{item.totalprice}}</td>
                                        <td>{{item.group_name}}</td>
                                    </tr>
                                </table>

                            </div>

                            <div v-if="Number(type) == 2">
                                <table class="layui-table"  lay-size="sm">
                                    <tr>
                                        <th>试剂名称</th>
                                        <th>试剂规格</th>
                                        <th>生产批号</th>
                                        <th>有效日期</th>
                                        <th>生产厂家</th>
                                        <th>数量</th>
                                        <th>价格</th>
                                        <th>单位</th>
                                        <th>金额</th>
                                        <th>组别</th>
                                    </tr>
                                    <tr @click="itemClick(item.reagent_info_name)" v-for="(item,key) in warn_list" :key="item.id">
                                        <td>{{item.reagent_info_name}}</td>
                                        <td>{{item.spec}}</td>
                                        <td>{{item.approval_number}}</td>
                                        <td>{{item.valid_time}}</td>
                                        <td>{{item.full_name}}</td>
                                        <td>{{item.stock_num}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.reagent_unit_name}}</td>
                                        <td>{{item.totalprice}}</td>
                                        <td>{{item.group_name}}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-if="Number(type) == 3">
                                <table class="layui-table"  lay-size="sm">
                                    <tr>
                                        <th>试剂名称</th>
                                        <th>试剂规格</th>
                                        <th>生产批号</th>
                                        <th>有效日期</th>
                                        <th>生产厂家</th>
                                        <th>数量</th>
                                        <th>价格</th>
                                        <th>单位</th>
                                        <th>金额</th>
                                        <th>组别</th>
                                    </tr>
                                    <tr @click="itemClick(item.reagent_info_name)" v-for="(item,key) in warn_list" :key="item.id">
                                        <td>{{item.reagent_info_name}}</td>
                                        <td>{{item.spec}}</td>
                                        <td>{{item.approval_number}}</td>
                                        <td>{{item.valid_time}}</td>
                                        <td>{{item.full_name}}</td>
                                        <td>{{item.stock_num}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.reagent_unit_name}}</td>
                                        <td>{{item.totalprice}}</td>
                                        <td>{{item.group_name}}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-if="Number(type) == 4">
                                <table class="layui-table"  lay-size="sm">
                                    <tr>
                                        <th>试剂名称</th>
                                        <th>试剂规格</th>
                                        <th>生产批号</th>
                                        <th>有效日期</th>
                                        <th>生产厂家</th>
                                        <th>数量</th>
                                        <th>价格</th>
                                        <th>单位</th>
                                        <th>金额</th>
                                        <th>组别</th>
                                    </tr>
                                    <tr @click="itemClick(item.reagent_info_name)" v-for="(item,key) in warn_list" :key="item.id">
                                        <td>{{item.reagent_info_name}}</td>
                                        <td>{{item.spec}}</td>
                                        <td>{{item.approval_number}}</td>
                                        <td>{{item.valid_time}}</td>
                                        <td>{{item.full_name}}</td>
                                        <td>{{item.stock_num}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.reagent_unit_name}}</td>
                                        <td>{{item.totalprice}}</td>
                                        <td>{{item.group_name}}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-if="Number(type) == 5">
                                <table class="layui-table"  lay-size="sm">
                                    <tr>
                                        <th>试剂名称</th>
                                        <th>试剂规格</th>
                                        <th>生产批号</th>
                                        <th>有效日期</th>
                                        <th>生产厂家</th>
                                        <th>数量</th>
                                        <th>价格</th>
                                        <th>单位</th>
                                        <th>金额</th>
                                        <th>组别</th>
                                    </tr>
                                    <tr @click="itemClick(item.reagent_info_name)" v-for="(item,key) in warn_list" :key="item.id">
                                        <td>{{item.reagent_info_name}}</td>
                                        <td>{{item.spec}}</td>
                                        <td>{{item.approval_number}}</td>
                                        <td>{{item.valid_time}}</td>
                                        <td>{{item.full_name}}</td>
                                        <td>{{item.stock_num}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.reagent_unit_name}}</td>
                                        <td>{{item.group_name}}</td>
                                    </tr>
                                </table>

                                <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>

                            </div>
                        </div>
                    </a-row>
                </div>
            </a-modal>
        </div>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel', 'jg_open','jinggaoClick'],
        data() {
            return {
                page: 1,
                count: 0,
                pagesize: 0,
                tab_active: '',
                warn_list: [],
                type: '1',
                tabPosition: 'left',
            }
        },
        watch: {

            $route: {
                handler() {
                    this.page = Number(this.$route.query.page) || 1;
                    this.Reagent_infostock_warn()
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page) || 1;
            this.Reagent_infostock_warn(1)// w报警列表
        },
        methods: {

            itemClick(name){
                this.jinggaoClick(name)
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                    }
                })
            },

            // w报警列表
            Reagent_infostock_warn(key) {
                this.$sa0.post({
                    url: this.$api('Reagent_infostock_warn'),
                    data: {
                        type: key,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.warn_list = response.data.result.list;
                            console.log(this.warn_list)
                            console.log(JSON.stringify(this.warn_list))
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            tabChange(key) {
                this.Reagent_infostock_warn(key)
            },

            // 定义 组件 自身的确定函数
            handleOk() {
                this.ok()
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
        }
    }
</script>
<style scoped>

    .nav-tab{width: 100%;}

    .ant-tabs .ant-tabs-left-bar{
        /*float: right !important;*/
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        /*width: 100% !important;*/
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }
</style>
