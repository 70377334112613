<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="试剂出库" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <p class="font-bold">试剂信息</p>
                <table>
                    <tr>
                        <td class="tag">试剂组别</td>
                        <td>{{chuku_info.group_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂名称</td>
                        <td>{{chuku_info.reagent_info_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">拼音代码</td>
                        <td>{{chuku_info.pinyin}}</td>
                    </tr>
                    <tr>
                        <td class="tag">生产厂家</td>
                        <td>{{chuku_info.full_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">批准文号</td>
                        <td>{{chuku_info.approval_number}}</td>
                    </tr>
                    <tr>
                        <td class="tag">有效日期</td>
                        <td>{{chuku_info.valid_time}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂规格</td>
                        <td>{{chuku_info.spec}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂状态</td>
                        <td>{{chuku_info.reagent_info_status}}</td>
                    </tr>
                    <tr>
                        <td class="tag">库存上限</td>
                        <td>{{chuku_info.Inventory_online}}</td>
                    </tr>
                    <tr>
                        <td class="tag">库存下限</td>
                        <td>{{chuku_info.Inventory_offline}}</td>
                    </tr>
                    <tr>
                        <td class="tag">存放地点</td>
                        <td>{{chuku_info.storage_location}}</td>
                    </tr>
                </table>
                <a-form id="components-form-demo-validate-other" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:8 }"  v-bind="formItemLayout" @submit="handleSubmit">

                    <a-form-item label="出库时间">
                            <a-date-picker show-time placeholder="请选择"
                                           :defaultValue="info.do_time"
                                           @change="(e,dateString)=>{onChangetime(e,dateString,'do_time')}" @ok="onOk"/>
                    </a-form-item>

                    <a-form-item label="领取人">
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }"
                                    :options="options" placeholder="请选择"  v-model="info.user_id"/>
                    </a-form-item>

                    <a-form-item label="出库数量">
                        <a-input v-model="info.stock_num" />
                    </a-form-item>

                    <a-form-item label="试剂价格">
                        <a-input  v-model="info.price" />
                    </a-form-item>

                    <a-form-item label="总金额">
                       <div>￥{{ info_total }}</div>
                    </a-form-item>

                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                            <a-button type="primary" class="mr20" @click="doClick()">确认出库</a-button>
                    </a-form-item>
                </a-form>

            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_time','chuku_info'],

        data: () => ({
            options:[],
            info:{
                reagent_info_id: '',
                stock_num: '',
                type: 1,
                ordernum: '',
                user_id:[],
                reagent_info_name: '',
                price: '',
                do_time: '',
                reason_id: '',
                storage_location: '',
                reagent_stock_id: '',
                valid_time:'',
                approval_number:'',
                batch:'',
                bar_code:'',
            },
            value: [],
            startValue: null,
            endValue: null,
            endOpen: false,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },
        }),

        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.chuku_info))
            },
        },
        //计算试剂总额
        computed:{
            info_total(){
                return (this.info.stock_num*this.info.price).toFixed(2)
            },
        },

        mounted() {


            this.info.reagent_info_id = this.chuku_info.reagent_info_id;
            this.info.reagent_info_name = this.chuku_info.reagent_info_name;
            this.info.reagent_stock_id = this.chuku_info.id;
            this.info.price = this.chuku_info.price;
            this.info.valid_time = this.chuku_info.valid_time;
            this.info.approval_number = this.chuku_info.approval_number;
            this.info.batch = this.chuku_info.batch;
            this.info.bar_code = this.chuku_info.bar_code;


            if (this.info.do_time == '') {
                this.info.do_time = this.formatDateTime(new Date() / 1)
            }
            this.Dandaalllist()
        },


        methods: {

            //显示当前时间
            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            },

            //出库
            doClick() {
                let data = {
                    reagent_info_id: this.info.reagent_info_id,
                    stock_num: this.info.stock_num,
                    type: 1,
                    ordernum: this.info.ordernum,
                    user_id: this.info.user_id[this.info.user_id.length - 1],
                    reagent_info_name: this.info.reagent_info_name,
                    price: this.info.price,
                    do_time: this.info.do_time,
                    reason_id: this.info.reason_id,
                    storage_location: this.info.storage_location,
                    reagent_stock_id: this.info.reagent_stock_id,
                    valid_time: this.info.valid_time,
                    approval_number: this.info.approval_number,
                    batch: this.info.batch,
                    bar_code: this.info.bar_code,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_reagentstock'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,

                        then: (res) => {
                            layer.msg('已出库')
                            this.close()

                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //获取部门人员分级列表
            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options = response.data.list;
                            console.log(this.options)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //时间组件
            onChangetime(value, dateString, e) {
                this.info[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },

            // 时间
            disabledStartDate(startValue) {
                const endValue = this.endValue;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.startValue;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            onChange(value) {
                console.log('changed', value);
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .mr20{ margin-right: 10px}
    table{
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }
    table td, table th{
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }
    table th{padding: 12px 15px; background: #f5f5f5}
    table td.tag{background: #f5f5f5; width: 18%; text-align: right; font-weight: bold}
</style>
