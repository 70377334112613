<template>
    <div>

        <jingshi :jinggaoClick="jinggaoClick" :jg_open="config.status" :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></jingshi>

        <div>
            <a-form-model layout="inline">
                <a-form-model-item>
                    <a-select v-model="formInline.group_id" style="width: 200px">
                        <a-select-option :value="0">全部试剂</a-select-option>
                        <a-select-option v-for="(item,key) in Group_list" :value="item.id">{{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item>
                    <a-select v-model="formInline.reagent_info" style="width: 160px">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">正常使用</a-select-option>
                        <a-select-option :value="1">失效试剂</a-select-option>
                        <a-select-option :value="2">近效试剂</a-select-option>
                        <a-select-option :value="3">超下限试剂</a-select-option>
                        <a-select-option :value="4">超上限试剂</a-select-option>
                        <a-select-option :value="5">零库存试剂</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item>
                    <a-input v-model="formInline.reagent_info_name" placeholder="请输入试剂名称或拼音代码" style="width: 260px"/>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="danger" @click="Clear()">清空</a-button>
                </a-form-model-item>


                <a-form-model-item>
                    <a-checkbox v-model="config.status" @change="checkboxclick()"></a-checkbox>
                    <span style="color: #ff2929; margin-left: 5px">选中不再出现试剂警示弹窗</span>
                </a-form-model-item>


                <a-form-model-item style="float: right">
                    <a-button type="primary" @click="Shijikucun_export">导出筛选数据</a-button>
                </a-form-model-item>
            </a-form-model>

        </div>

        <table class="layui-table" style="margin-top: -20px" lay-size="sm">
            <thead>
            <tr>
                <th>试剂名称</th>
                <th>拼音代码</th>
                <th>试剂规格</th>
                <th>条形码</th>
                <th>生产厂家</th>
                <th>批准文号</th>
                <th>生产批号</th>
                <th>有效日期</th>
                <th>试剂数量</th>
                <th>试剂组别</th>
                <th>试剂状态</th>
                <th>库存上限</th>
                <th>库存下限</th>
                <!--                <th>试剂价格</th>-->
                <th>存放地点</th>
                <!--                <th>操作时间</th>-->
                <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in Reagent_infostock_list" :key="item.id">
                <!--                {{ item }}-->
                <td>{{item.reagent_info_name}}</td>
                <td>{{item.pinyin}}</td>
                <td>{{item.spec}}</td>
                <td>{{item.bar_code}}</td>
                <td>{{item.full_name}}</td>
                <td>{{item.approval_number}}</td>
                <td>{{item.batch}}</td>
                <td>{{item.valid_time}}</td>
                <td>{{item.stock_num}}</td>
                <td>{{item.group_name}}</td>
                <template>
                    <td :class="infoStatus(item.info_status)">{{item.reagent_info_status}}</td>
                </template>
                <td>{{item.Inventory_online}}</td>
                <td>{{item.Inventory_offline}}</td>
                <!--                <td>{{item.price}}</td>-->
                <td>{{item.storage_location_name}}</td>
                <!--                <td>{{item.do_time}}</td>-->
                <td>
                    <a-space>

                        <a-button type="link" @click="showDrawer2(item)" v-if="item.exit == 0 || item.info_status == 5">
                            编辑
                        </a-button>

                        <a-button type="link" class="yellow" @click="showDrawer(item)" v-if="checkButtonShow(item.info_status,['2','4','6','2,4'])">
                            出库
                        </a-button>

                        <a-button type="link" class="redcolor" @click="showDrawer1(item)" v-if="checkButtonShow(item.info_status,['1','2','3','4','6','1,3','1,4','2,3','2,4'])">
                            报损
                        </a-button>
                    </a-space>
                </td>
            </tr>
            </tbody>

        </table>
        <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        <!--出库-->
        <div v-if="chuku_info">
            <labopen :close="onCloseDrawer" :edit_time="edit_time" :chuku_info="chuku_info" :visible="drawer_visible"></labopen>
        </div>
        <!--报损-->
        <div v-if="shiji_info">
            <baosunopen :close="onCloseDrawer1" :shiji_info="shiji_info" :visible="drawer_visible1"></baosunopen>
        </div>
        <!--没有出库、没有报损的可以编辑入库-->
        <div v-if="edit_ruku">
            <editopen :close="onCloseDrawer2" :edit_ruku="edit_ruku" :visible="drawer_visible2"></editopen>
        </div>
    </div>
</template>

<script>
    import jingshi from '../../../components/drawer/shijiguanli-jingshi/shijiguanli-jingshi.vue';
    import labopen from "../../../components/drawer/shijiguanli-chuku/shijiguanli-chuku.vue";
    import baosunopen from "../../../components/drawer/shijiguanli-baosun/shijiguanli-baosun.vue";
    import editopen from "../../../components/drawer/shijiguanli-edit/shijiguanli-edit.vue";
    //import config from "../../../config";

    export default {
        components: {
            labopen,
            baosunopen,
            editopen,
            jingshi
        },
        data() {
            return {
                config: {
                    status: false
                },
                formInline: {
                    group_id: 0,
                    reagent_info_name: '',
                    reagent_info: '',
                },
                Group_list: [],
                edit_time: 0,
                //分页
                page: 1,
                count: 0,
                pagesize: 0,
                //获取试剂管理列表
                Reagent_infostock_list: [],
                // 警示弹窗变量
                modal_visible: true,
                // 定义控制抽屉显示的变量
                chuku_info: false,
                drawer_visible: false,//出库
                shiji_info: false,
                drawer_visible1: false,//报损
                edit_ruku: false,
                drawer_visible2: false,//编辑入库
                page_show: false,//编辑入库
            };
        },
        watch: {
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.config.status = Number(this.config.status) === 1
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            jinggaoClick(name) {
                this.modal_visible = false;
                this.formInline.reagent_info_name = name
            },
            checkboxclick() {
                this.$sa0.localStorage.set('JINGGAO_OPEN', this.config.status ? '1' : '2')
            },
            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.formInline.group_id = Number(this.$route.query.group_id) || 0;
                this.formInline.reagent_info_name = this.$route.query.reagent_info_name || '';
                this.formInline.reagent_info = this.checkReagentInfo();
                let status = this.$sa0.localStorage.get('JINGGAO_OPEN')
                if (!status) status = '1'
                this.config.status = status === '1'
                this.Reagent_infostock()
                this.Sampletypelist()// w获取试剂组别列表
                setTimeout(() => {
                    this.page_show = true
                }, 1)
            },
            checkReagentInfo() {
                if (this.$route.query.reagent_info) {
                    if (this.$route.query.reagent_info === '0') {
                        return 0
                    } else {
                        return Number(this.$route.query.reagent_info)
                    }
                } else {
                    return ''
                }
            },
            searchClick() {
                this.$router.push({
                    query: {
                        group_id: this.formInline.group_id,
                        reagent_info_name: this.formInline.reagent_info_name,
                        reagent_info: this.formInline.reagent_info,
                        page: 1,
                    },
                })
            },

            Clear() {
                this.$router.push({
                    query: {
                        group_id: 0,
                        reagent_info_name: '',
                        reagent_info: '',
                        page: 1,
                    }
                })
            },

            checkButtonShow(status_str, show_arr) {
                status_str = status_str.toString()
                let show = false
                if (show_arr.indexOf(status_str) !== -1) {
                    show = true
                }
                let status_arr = status_str.split(',')
                status_arr.map((item) => {
                    if (show_arr.indexOf(Number(item)) !== -1) {
                        show = true
                    }
                })
                return show
            },


            //切换试剂状态样式
            infoStatus(status) { // 1,3
                let status_arr = (status.toString()).split(',')
                let red_arr = [1, 3, 4, 5];
                let green_arr = [2];
                let res_c = '';
                status_arr.map((item) => {
                    if (red_arr.indexOf(Number(item)) !== -1) {
                        res_c = 'darkred';
                    }
                    if (green_arr.indexOf(Number(item)) !== -1) {
                        res_c = 'green';
                    }
                })
                return res_c;
            },

            //获取试剂组别列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 4,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Group_list = response.data.list;
                            // this.tab_active = this.Group_list[0].id
                            // this.Group_listItem = this.Group_list[0]
                            // this.Get_reagentinfo(this.tab_active)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                    }
                })
            },
            //试剂信息导出
            Shijikucun_export() {
                console.log(JSON.stringify({
                    page: this.page,
                    group_id: this.formInline.group_id,
                    reagent_info_name: this.formInline.reagent_info_name,
                    reagent_info: this.formInline.reagent_info,
                }))
                this.$sa0.down({
                    url: this.$api('Shijikucun_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        page: this.page,
                        group_id: this.formInline.group_id,
                        reagent_info_name: this.formInline.reagent_info_name,
                        reagent_info: this.formInline.reagent_info,
                    },
                },)
            },

            //获取试剂管理列表
            Reagent_infostock() {
                console.log(JSON.stringify({
                    page: this.page,
                    group_id: this.formInline.group_id,
                    reagent_info_name: this.formInline.reagent_info_name,
                    reagent_info: this.formInline.reagent_info,
                }))
                this.$sa0.post({
                    url: this.$api('Reagent_infostock'),
                    data: {
                        page: this.page,
                        group_id: this.formInline.group_id,
                        reagent_info_name: this.formInline.reagent_info_name,
                        reagent_info: this.formInline.reagent_info,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Reagent_infostock_list = response.data.result.list;
                            //console.log(this.Reagent_infostock_list)
                            //console.log(JSON.stringify(this.Reagent_infostock_list))
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 打开抽屉时的 函数 出库
            showDrawer(item) {
                this.chuku_info = item
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Reagent_infostock();
                this.chuku_info = false;
            },

            // 定义 打开抽屉时的 函数 报损
            showDrawer1(item) {
                this.shiji_info = item;
                this.drawer_visible1 = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer1() {
                this.drawer_visible1 = false;
                this.Reagent_infostock();
                this.shiji_info = false;
            },

            // 定义 打开抽屉时的 函数 编辑入库
            showDrawer2(item) {
                this.edit_ruku = item;
                this.drawer_visible2 = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer2() {
                this.drawer_visible2 = false;
                this.Reagent_infostock();
                this.edit_ruku = false
            },

            // 定义 打开对话框时的 函数  警示窗口
            showModal() {
                this.modal_visible = true
            },
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },


        },
    };
</script>

<style scoped>
    .darkred{
        color: darkred
        }

    .green{
        color: green
        }
</style>
